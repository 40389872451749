/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

import "./blaze/blaze.scss"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          org {
            name,
            taxId
          }
          menuLinks {
            name
            path
          }
        }
      }
    }
  `)

  const menuLinks = data.site.siteMetadata.menuLinks.map(menuLink => (
    <li key={menuLink.name}><Link to={`${menuLink.path}#main-body`}>{menuLink.name}</Link></li>
  ));

  return (
    <div id="border">
      <div id="main-title">
        <h1>{data.site.siteMetadata.title}</h1>
        <h2 className="hide-on-print">{data.site.siteMetadata.description}</h2>
      </div>
      <div id="main-body" role="main">
        <main>{children}</main>
      </div>

      <div id="footer" className="hide-on-print">
        <div className="o-grid o-grid--wrap">
          <div
            className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-66@large"
            itemScope={true}
            itemType="http://schema.org/Organization"
            >
              <h3 itemProp="name">{data.site.siteMetadata.org.name}</h3>
              <h4>R/K <span itemProp="taxID">{data.site.siteMetadata.org.taxId}</span></h4>
            </div>

          <div className="o-grid__cell o-grid__cell--width-100 o-grid__cell--width-33@large">
            <h3>Sisu</h3>
            <ul className="navigation" role="navigation">
              {menuLinks}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
