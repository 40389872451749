import React from "react"

const Rule = (props) => {
    let className = 'main-rule';
    if (props.last) {
        className += ' last';
    }
    return (
        <div className={className}>&nbsp;</div>
    );
};

export default Rule
